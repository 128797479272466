
.header {
    background-color: #000;
    color: white;
    position: flex;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .ssearch {
    height: 50px;
    align-items: center;
    display: flex;
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 50px;
  }
  
  .logo {
    font-size: 1rem;
    cursor: pointer;
    
  }

  .header-right {
    height: 50px;
    display: flex;
    align-items: center;
  }

  
  .nav-list {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
  }
  
  .nav-item a {
    text-decoration: none;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .nav-item a:hover {
    color: #00bcd4;
  }
  
  /* Hamburger Menu */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 3px;
  }
  
  .bar {
    width: 16px;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }

  .user-profile-button {
    padding-right: 20px;
  }

  
  /* 반응형형 */
  @media screen and (max-width: 768px) {
    .nav {
      flex-direction: column;
      background-color: #000;
      top: 50px;
      right: 0;
      width: 100%;
      z-index: 999;
      position: absolute;
      
    }

    .nav-list.is-active {
      display: flex; /* 햄버거 버튼 활성화 시 표시 */
      
    }
  
  
    .nav-list {
      display: none;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      
    }
  
    .hamburger {
      display: flex;
    }
    
    .ssearch {
      display: flex;
    }
    
  }



/* 검색창스타일 */
.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

/* 검색 입력창 스타일 */
.search-input {
  width: 100px;
  padding: 10px;
  font-size: 11px;
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
}


/* 검색 버튼 스타일 */
.search-button {
  
  color: white;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
}



/* 반응형 디자인 */
@media (max-width: 768px) {
  .search-input {
    width: 100px;
    font-size: 11px;
  }
}


/* Footer container */
.footers {
  background-color: #181B20;
  color: white;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Columns container */
.columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 15px;
  box-sizing: border-box;
}

/* Individual column */
.column {
  flex: 1;
  padding: 10px;
}

/* Logo and copyright section */



.footer-logo-box {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.footer-logo-text {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.footer-copyright {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-top: 10px;
}

.footer-link-box {
  align-items: center;
}

.iconbox {
  display: flex;
  gap: 10px;
}

/* Contact email */
.footer-email-link {
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

/* Social icons container */
.icon-button {
  margin-top: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
}

/* Instagram icon */
.insta-icon {
  width: 100%;
  height: 100%;
  background-image: url('../assets/image/insta.png');
  background-position: center;
  background-size: cover;
}

/* YouTube icon */
.youtube-icon {
  width: 100%;
  height: 70%;
  background-image: url('../assets/image/youtube.svg');
  background-position: center;
  background-size: cover;
}

/* Buttons */
.black-button {
  border-radius: 46px;
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.black-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

.white-button {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 46px;
  width: 93px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.white-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}



