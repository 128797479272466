.carousel-container {
  display: flex;
  justify-content: center;
  align-items:start;
  padding-top: 1vh;
  height: 110vh; /* 화면 높이를 꽉 채움 */
  background-color: #000000; /* 배경색 */ /* 화면이 작아졌을 때 여백을 추가 */
}

.slick-slider {
  width: 100%;
  height: 90%;
}

.slick-slide {
  padding: 0 10px; /* 좌우에 10px 패딩 */
}



.slick-slide img {
  width: 100%;
  height: 800px;
  border-radius: 5px;
  object-fit: cover;
}


.slick-slide img:hover {
  transform: scale(1.01);
  transition: transform 0.5s ease;
}


.slick-dots {
  bottom: 50px !important; /* 슬라이더 내부 아래로 위치 */
}

.slick-dots li button:before {
  color: #373737; /* dots 색상 */
}

.slide-item {
  position: relative; /* 상대 위치 설정 */
}

.text-overlay {
  position: absolute; /* 절대 위치 설정 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 중앙 정렬 */
  text-align: center;
  color: white; /* 글자 색상 */
}

.text-overlay h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.text-overlay p {
  font-size: 1em;
  margin-bottom: 20px;
}

.text-overlay button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 20px; /* 버튼 모서리 둥글게 */
}