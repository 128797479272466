#pagination-wrapper {
    display: flex;
    gap: 20px;

}

.pagination-change-link-box {
    display: flex;
    align-items: center;
    gap:4px;

    cursor: pointer;
}

.pagination-change-link-text {
    color: rgba(255, 255, 255, 1);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
}

.pagination-divider {
    color: rgba(255, 255, 255, 0.1);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; 

    cursor: default;
}

.pagination-text-active {
    color: rgba(255, 255, 255, 1);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; 

    cursor: default;
}

.pagination-text {
    color: rgba(0, 0, 0, 0.4);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; 

    cursor: pointer;
}

.icon-box-small {
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width:100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.expand-left-icon {
    background-image: url(../assets/image/expand-left.png);
}

.expand-right-icon {
    background-image: url(../assets/image/expand-right.png);
}


