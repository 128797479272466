#auth-wrapper {
    padding: 100px 0px 150px;
    /*     이거 민 위드 */
    min-width: 1000px;
    height: calc(100vh - 68px - 100px - 150px);
    min-height: 1000px;

    display: flex;
    justify-content:  center;
    align-items:  center;

    background-image: url(../../assets/image/black.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.auth-container {
    width:1200px;
    height:100%;

    display: grid;
    grid-template-columns: 7fr 5fr;
    column-gap: 78px;
}

.auth-jumbotron-box {
    grid-column: 1 / 2;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-jumbotron-contents {
    display: flex;
    align-items: center;
    gap:20px;
    flex-direction: column;
    min-width: 300px;
}

.auth-logo-icon {
    width: 50px;
    height: 50px;

    background-image: url(../../assets/image/footericon.png);
    background-position: 50% 50%;
    background-size: 100% 100%;
}

.auth-jumbotron-text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.auth-jumbotron-text {
    color: rgba(255, 255, 255, 1);
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
}

.auth-card {
    width:400px;
    border-radius: 10px;
    padding: 50px 50px 30px;
    background-color: rgba(255, 255, 255, 1);}

.auth-card-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.auth-card-top{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.auth-card-title-box {
    display: flex;
    justify-content: space-between;
}

.auth-card-title {
    color: rgba(0, 0, 0, 1);

    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.auth-card-page {
    color: rgba(0, 0, 0, 0.7);

    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.auth-card-bottom {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.auth-sign-in-error-box {
    margin-bottom: -4px;

}

.auth-sign-in-error-message {
    color: rgba(255, 0, 0, 0.7);

    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    white-space: pre-wrap;
}

.auth-description-box {
    display: flex;
    justify-content: center;

}

.auth-description {
    color: rgba(0, 0, 0, 0.7);

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.auth-description-link {
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    font-weight: 600;
}

.auth-consent-box {
    display: flex;
    align-items: center;
    gap:6px;
}


.auth-consent-title {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.auth-consent-title-error {
    color: rgba(255, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.auth-consent-link {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    cursor: pointer;
}


.black-large-full-button {
    width: 100%;
    height: 48px;

    display: flex;
    justify-content: center;
    border-radius: 46px;
    align-items: center;

    background-color: rgba(0, 0, 0, 1);

    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.black-large-full-button:hover {
    background-color: rgba(0, 0, 0, 0.85);
}






.inputbox {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputbox-label {
    color: rgba(0, 0, 0, 0.7);

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 11px 16px 11px 0px;

    display: flex;
    align-items: center;
}

.inputbox-container-error {
    border-bottom: 1px solid rgba(255, 0, 0, 0.7);
    padding: 11px 16px 11px 0px;

    background-color: rgba(250, 250, 250, 1);

    display: flex;
    align-items: center;
}

.input {
    border: none;
    background: none;
    outline: none;

    flex:1;

    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-message {
    color: rgba(255, 0, 0, 0.7);

    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
}

.eye-light-off-icon {
    width:100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: url(../../assets/image/eye-light-off.png);

}

.eye-light-on-icon {
    width:100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: url(../../assets/image/eye-light-on.png);
    
}

.auth-check-box {
    cursor: pointer;

    width:24px;
    height: 24px;
}

.check-round-fill-icon {
    background-image: url(../../assets/image/check-round-fill.png);
}

.check-ring-light-icon {
    background-image: url(../../assets/image/check-ring-light.png);
}

.icon {
    width:100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
}



.google-button {
    width: 100%;
    height: 48px;

    display: flex;
    justify-content: center;
    border-radius: 46px;
    align-items: center;

    background-color: rgba(255, 40, 10, 1);

    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.google-button:hover {
    background-color: rgba(255, 40, 10, 0.7);
}

.google-profile-box {
    width: 25px;
    height: 25px;
}

.google-profile-image {
    border-radius : 50% 50%;
    width: 100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: url(../../assets/image/google.png);
}



.github-button {
    width: 100%;
    height: 48px;

    display: flex;
    justify-content: center;
    border-radius: 46px;
    align-items: center;

    background-color: rgba(0, 0, 0, 1);

    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.github-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.github-profile-box {
    width: 28px;
    height: 28px;
}

.github-profile-image {
    border-radius : 50% 50%;
    width: 100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: url(../../assets/image/github.png);
}