.container1 {
    background-color: #393939;
}

#board-detail-top {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #393939;
}

.board-detail-top-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-title {
    color: #ffffff;
    font-size: 25px;
    font-weight: 400;
    line-height: 140%;
}

.board-detail-top-sub-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.board-detail-write-info-box {
    display: flex;
    align-items: center;
    gap: 8px;
}

.board-detail-writer-profile-image {
    border-radius: 32px;
    width: 32px;
    height: 32px;
    background-position: 50% 50%;
    background-size: 100% 100%;
}

.board-detail-writer-nickname {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.board-detail-info-divider, 
.board-detail-write-date {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 140%;
}

.board-detail-more-box {
    position: absolute;
    top: -81px;
    right: 0px;
    background-color: #2a2a2a;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}

.board-detail-update-button {
    width: 130px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-update-button:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}

.board-detail-delete-button {
    width: 130px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 0, 0, 0.7);
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-delete-button:hover {
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.1);
}

.board-detail-top-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-main-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-weight: 500;
    line-height: 150%;
    white-space: pre-wrap;
}

#board-detail-bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #393939;
}

.board-detail-bottom-button-box {
    display: flex;
    gap: 12px;
}

.board-detail-bottom-button-group {
    display: flex;
    align-items: center;
    gap: 6px;
}

.board-detail-bottom-button-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-favortie-box {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 20px 40px;
    background-color: #9c8f8f;
}

.board-detail-bottom-favorite-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-bottom-favorite-title {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-favorite-contents {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;
}

.board-detail-bottom-comment-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #393939;
}

.board-detail-bottom-comment-container {
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-bottom-comment-title {
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-comment-list-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.board-detail-bottom-comment-pagination-box {
    display: flex;
    justify-content: center;
}

.board-detail-bottom-comment-input-box {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    background-color: #393939;
}

.board-detail-bottom-comment-input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-bottom-comment-textarea {
    border: none;
    outline: none;
    background: none;
    resize: none;
    overflow: hidden;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
}

.board-detail-bottom-comment-button-box {
    display: flex;
    justify-content: flex-end;
}

.icon-button {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: 100% 100%;
    filter: invert(1);
}

.more-icon {
    background-image: url(../../assets/image/more.png);
}

.divider {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
}

.favorite-fill-icon {
    background-image: url(../../assets/image/favorite-fill.png);
}

.favorite-light-icon {
    background-image: url(../../assets/image/favorite-light.png);
}

.up-light-icon {
    background-image: url(../../assets/image/expand-up-light.png);
}

.down-light-icon {
    background-image: url(../../assets/image/expand-down-light.png);
}

.comment-icon {
    background-image: url(../../assets/image/chat-light.png);
}

.emphasis {
    font-weight: bolder;
    color: #ffffff;
}

.disable-button {
    border-radius: 46px;
    width: 95px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: not-allowed;
}

.black-button {
    border-radius: 46px;
    width: 95px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.black-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
}



.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10vh 1vh;
}

/* 댓글 입력창 placeholder 스타일 */
.board-detail-bottom-comment-textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

/* 댓글 입력창 포커스 시 스타일 */
.board-detail-bottom-comment-textarea:focus {
    background-color: rgba(255, 255, 255, 0.05);
}

/* 버튼 비활성화 상태 스타일 */
.black-button:disabled {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
}

/* 좋아요 버튼 활성화 상태 */
.favorite-button.active {
    color: #ff0000;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
    .board-detail-title {
        font-size: 20px;
    }

    .board-detail-bottom-favorite-contents {
        gap: 15px;
    }

    .board-detail-bottom-comment-container {
        padding: 30px 15px;
    }

    .black-button,
    .disable-button {
        width: 80px;
        height: 28px;
        font-size: 12px;
    }
}

/* 다크 모드에서의 스크롤바 스타일 */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* 선택 텍스트 스타일 */
::selection {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

/* 에러 메시지 스타일 */
.error-message {
    color: #ff6b6b;
    font-size: 12px;
    margin-top: 5px;
}

/* 로딩 상태 스타일 */
.loading {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding: 20px;
}



/* 선택 텍스트 스타일 (크로스 브라우저 지원)  드래그할때 글자색*/
::selection {
    background-color: rgba(255, 255, 255, 0.2);
    color: #000000;
}

::-moz-selection {
    background-color: rgba(255, 255, 255, 0.2);
    color: #000000;
}
