#board-write-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.05);

}

.board-write-container {
    padding:  100px 24px;
    
    min-height: 1952px;

    background-color: #393939;

}

.board-write-box {
    display: flex;
    flex-direction: column;
    gap:40px;
}

.board-write-title-box {
    width: 100%;

}

.board-write-title-textarea {
    width: 100%;

    border: none;
    outline:  none;
    background: none;
    resize: none;

    color: rgba(0, 0, 0, 1);

    font-size: 32px;
    font-weight: 500;
    line-height: 140%;
}

.board-write-content-box {
    width: 100%;

    display: flex;
    gap: 16px;
}

.board-write-content-textarea {
    color: rgba(0, 0, 0, 0.7);

    flex: 1;
    border:  none;
    outline: none;
    background: none;
    resize: none;

    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
}

.board-write-images-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-write-image-box {
    position: relative;
    width: 100%;

}

.board-write-image {
    width: 100%;
}

.image-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.disable-button {
    border-radius: 46px;
    width: 95px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.7);

    color: rgb(0, 0, 0);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%;

    cursor: not-allowed;
}

.black-button {
    border-radius: 46px;
    width: 95px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 1);

    color: rgb(0, 0, 0);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%;

    cursor: pointer;
}

.black-button:hover {
    background-color: rgba(0, 0, 0, 0.85);
}