.user-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #000000;
  }
  
  .user-profile-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .profile-image-box {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease;
  }
  
  .profile-image-box:hover {
    border-color: #171819;
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .user-info h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .user-info p {
    margin: 5px 0 0;
    font-size: 16px;
    color: #777;
  }
  
  .nickname-edit input {
    font-size: 16px;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .nickname-edit button {
    background-color: #2e343b;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nickname-edit button:hover {
    background-color: #2e343b;
  }
  
  .write-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .write-button button {
    background-color: #474141;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .write-button button:hover {
    background-color: #0056b3;
  }
  