.card {
  width: 300px; /* 고정 가로 크기 */
  height: 450px; /* 고정 세로 크기 */
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* 중앙 정렬 */
}

.card-image-container {
  height: 300px; /* 고정 높이 */
  overflow: hidden;
}

.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-contents {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #080808;
  flex-grow: 1; /* 남은 공간 채우기 */
}

.card-title {
  font-size: 18px;
  margin-bottom: 30px;
  color: #ffffff;
}

.card-date {
  font-size: 12px;
  color: #ffffff;
}

/* 화면 크기에 상관없이 고정 크기 유지 */
@media (max-width: 768px) {
  .card {
    width: 300px; /* 고정 가로 크기 */
    height: 450px; /* 고정 세로 크기 */
  }

  .card-image-container {
    height: 300px; /* 고정 높이 */
  }
}
