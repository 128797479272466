/* Embla Carousel 스타일 */
.embla-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5vh;
}

.carousel-header {
  padding: 0vh 2vh 0vh 2vh;
  display: flex;
  justify-content: space-between; /* 버튼과 타이틀 양쪽 배치 */
  align-items: center;
  margin-bottom: 10px;
}

.project-title {
  font-size: 1.5rem;
  color: white;
  margin: 0;
}

.carousel-buttons {
  display: flex;
  gap: 10px;
}

.embla__button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 3px;
}

.embla__button:hover {
  background-color: #555;
}

/* Embla Carousel 본체 */
.embla {
  overflow: hidden;
  width: 100%;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  position: relative;
  flex: 0 0 300px; /* 카드 크기에 맞게 고정 */
  margin-left: 10px;
}
