/* General container styling */
.search-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #0b0b0b;
  }
  
  /* Grid layout for articles */
  .search-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    gap: 20px;
    justify-items: center;
    align-items: start;
  }
  
  /* Empty result message */
  .search-contents-nothing {
    margin-top: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
  }
  
  /* Pagination box styling */
  .search-pagination-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  
  /* Item card responsiveness */
  @media (max-width: 1200px) {
    .search-grid {
      grid-template-columns: repeat(3, 1fr); /* Three items per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .search-grid {
      grid-template-columns: repeat(2, 1fr); /* Two items per row on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .search-grid {
      grid-template-columns: 1fr; /* One item per row on extra-small screens */
    }
  }
  